import React from "react"
import Navbar from "./../../components/Navbar/Navbar"
import { Container, Row, Col, Spinner, Form } from "react-bootstrap"
import { navigate } from "gatsby"
import FirebaseApp from "./../../config/firebase"
import { AiFillCheckCircle } from "react-icons/ai"
import "./index.css"
let database = FirebaseApp.database().ref("/")
export default class ApplyNewForCourse extends React.Component {
  constructor() {
    super()
    this.state = {
      completedCourses: [],
      course: "",
      MobileAppCretificate: "",
      GrapichCretificate: "",
      url: "",
      err: "",
      disabled: "",
      isLoader: false,
      user: {},
      Batches: {},
    }
  }

  componentDidMount = () => {
    let batche = []
    FirebaseApp.auth().onAuthStateChanged(async user => {
      if (user && user.emailVerified) {
        database.child("Registration/" + user.uid + "/").once("value", v => {
          let value = v.val()
          this.setState({ user: value })
        })
        database
          .child("batches/")
          .orderByChild("batch_name")
          .limitToLast(1)
          .on("child_added", snapshot => {
            console.log(snapshot.val())
            this.setState({ Batches: snapshot.val() })
          })
      } else {
        navigate("/") //navigate to edit page
      }
    })
  }

  getImageURL = async (e, value) => {
    if (e.target.files.length !== 0) {
      let imageName = e.target.files[0].name
      var imageType = e.target.files[0].type
      let ref = FirebaseApp.storage()
        .ref("/")
        .child(`reApplyImages/${this.state.course + imageName}`)
      if (imageType === "image/png" || imageType === "image/jpeg") {
        this.setState({ disabled: true, err: "" })
        await ref.put(e.target.files[0])
        ref.getDownloadURL().then(url => {
          if (value === "Mobile App Development") {
            this.setState({ disabled: false, MobileAppCretificate: url })
          }
          if (value === "Video Editing") {
            this.setState({ disabled: false, GrapichCretificate: url })
          }
        })
      } else {
        this.setState({ err: "Please select file in jpeg or png format..." })
      }
    } else {
      this.setState({ disabled: false, err: "" })
    }
  }

  _applyNow = () => {
    let {
      course,
      MobileAppCretificate,
      GrapichCretificate,
      url,
      err,
      disabled,
      isLoader,
      user,
      completedCourses,
      Batches,
    } = this.state
    if (course === "" || course === "Select Courses") {
      this.setState({ isLoader: false, err: "Please select any one course" })
    } else if (
      course === "Mobile App Development" &&
      MobileAppCretificate === ""
    ) {
      this.setState({
        isLoader: false,
        err: "Required web developement certification for apply in this course",
      })
    } else if (course === "Video Editing" && GrapichCretificate === "") {
      this.setState({
        isLoader: false,
        err: "Required Graphic design cretificate for apply in this course",
      })
    } else {
      var obj = {
        url: user.url,
        approved: false,
        course: course,
        email: user.email,
        fatherName: user.fatherName,
        mobileNum: user.mobileNum,
        name: user.name,
        nicNumber: user.nicNumber,
        qualification: user.qualification,
        systemToken: user.systemToken,
        uid: user.uid,
        completedCourses: completedCourses,
        isCompleted: "inProgress",
        batch: Batches.batch_name,
      }
      if (GrapichCretificate !== "") {
        obj.certificate = GrapichCretificate
      } else if (MobileAppCretificate !== "") {
        obj.certificate = MobileAppCretificate
      }
      if (course === "Freelancing") {
        obj.oneTimeFees = true
        obj.totalFees = 7500
        obj.addmissionFees = 7500
        obj.duration = false
      }else if (course === "Social Media Marketing") {
        obj.oneTimeFees = true
        obj.totalFees = 2999
        obj.addmissionFees = 2999
        obj.duration = false
      }else if (course === "Youtube Mastery") {
        obj.oneTimeFees = true
        obj.totalFees = 2399
        obj.addmissionFees = 2399
        obj.duration = false
      }else if (course === "Web Development & Web Designing") {
        obj.oneTimeFees = false
        obj.totalFees = 23000
        obj.addmissionFees = 5000
        obj.monthlyFees = 3000
        obj.duration = [
          {
            month: "First Month",
            paid: false,
          },
          {
            month: "Second Month",
            paid: false,
          },
          {
            month: "Third Month",
            paid: false,
          },
          {
            month: "Fourth Month",
            paid: false,
          },
          {
            month: "Fifth Month",
            paid: false,
          },
          {
            month: "Sixth Month",
            paid: false,
          },
        ]
      } else {
        obj.oneTimeFees = false
        obj.totalFees = 14000
        obj.addmissionFees = 5000
        obj.monthlyFees = 3000
        obj.duration = [
          {
            month: "First Month",
            paid: false,
          },
          {
            month: "Second Month",
            paid: false,
          },
          {
            month: "Third Month",
            paid: false,
          },
        ]
      }
      this.setState({ isLoader: true })
      database.child("Registration/" + `${user.uid}/`).set(obj)
      this.setState({ isLoader: false })
      navigate("/courses")
    }
  }

  render() {
    let {
      course,
      MobileAppCretificate,
      GrapichCretificate,
      url,
      err,
      disabled,
      isLoader,
      user,
    } = this.state
    return (
      <div>
        <Navbar notification="hide" />
        <Container>
          <Row className="justify-content-center">
            {user &&
            user.completedCourses &&
            user.completedCourses.length !== 0 ? (
              user.completedCourses.map((val, i) => {
                return (
                  <Col lg="6" key={i}>
                    <div className="_completed_course">
                      <span class="_lable status_completed">completed </span>
                      <div style={{ display: "flex", flexWrap: "wrap" }}>
                        <span className="_congrat_head">
                          Congratulations you completed {val.course} course
                        </span>
                      </div>
                    </div>
                  </Col>
                )
              })
            ) : (
              <h1>you did not completed any course yet!</h1>
            )}
          </Row>
        </Container>

        <Container>
          <Row className="justify-content-center">
            <Col lg={6}>
              <div className="_apply_new_course">
                <div className="_apply_heading">Apply for new course</div>
                <select
                  className="_select_Course "
                  style={{ width: "100%" }}
                  value={course}
                  onChange={e =>
                    this.setState({ course: e.target.value, err: "" })
                  }
                >
                  <option selected style={{ color: "grey" }}>
                    Select new Courses
                  </option>
                  <option>Mobile App Development</option>
                  <option>E-commerce Course</option>
                  <option>Web Development & Web Designing</option>
                  <option>Freelancing</option>
                  <option>Social Media Marketing</option>
                  <option>Youtube Mastery</option>
                  {/* <option>Digital Marketing</option> */}
                  <option>Graphic Design</option>
                  <option>Video Editing</option>
                  <option>Wordpress</option>
                </select>
                {course === "Mobile App Development" ? (
                  <Form.Group>
                    <Form.File
                      id="exampleFormControlFile1"
                      label={
                        MobileAppCretificate === "" ? (
                          "upload your web development certificate"
                        ) : (
                          <div className="_upload_pic_css">
                            {" "}
                            <div>uploaded certification successfullly </div>
                            <AiFillCheckCircle />
                          </div>
                        )
                      }
                      className="_upload_new_course"
                      onChange={e =>
                        this.getImageURL(e, "Mobile App Development")
                      }
                    />
                  </Form.Group>
                ) : null}

                {course === "Video Editing" ? (
                  <Form.Group>
                    <Form.File
                      id="exampleFormControlFile2"
                      label={
                        GrapichCretificate === "" ? (
                          "upload your graphic designing certificate"
                        ) : (
                          <div className="_upload_pic_css">
                            {" "}
                            <div>uploaded certification successfullly </div>
                            <AiFillCheckCircle />
                          </div>
                        )
                      }
                      className="_upload_new_course"
                      onChange={e => this.getImageURL(e, "Video Editing")}
                    />
                  </Form.Group>
                ) : null}
                {url !== "" ? (
                  <div className="_image_caption" style={{ color: "green" }}>
                    uploaded pic successfully
                  </div>
                ) : null}
                <div
                  className="_err"
                  style={{ textAlign: "left", width: "100%", fontSize: "10px" }}
                >
                  {err}
                </div>
                <p style={{ textAlign: "right" }}>
                  <button
                    className="_apply_btn re_apply"
                    onClick={() => this._applyNow()}
                    disabled={disabled}
                    style={disabled ? { opacity: 0.5 } : { opacity: 1 }}
                  >
                    {isLoader ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      "Apply"
                    )}
                  </button>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}
